<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Head } from '@inertiajs/vue3'

const { t } = useI18n()

const props = defineProps<{
    title?: string,
    description?: string,
    page?: number,
}>()

const title = computed(() => {
    if (props.title === null) {
        return null
    }

    if (props.page && props.page > 1) {
        return t('components.head.paginated-title', { title: props.title, page: props.page })
    }

    return props.title
})

const pageTitle = computed(() => {
    return title.value ? `${title.value} | ${t('components.head.app-name')}` : t('components.head.app-name')
})

const description = computed(() => {
    if (!props.description) {
        return null
    }

    if (props.page && props.page > 1) {
        return t('components.head.paginated-description', { description: props.description, page: props.page })
    }

    return props.description
})
</script>

<template>
    <Head :title="pageTitle">
        <meta property="og:title" :content="pageTitle">

        <template v-if="description">
            <meta name="description" :content="description">
            <meta property="og:description" :content="description">
        </template>

        <slot />
    </Head>
</template>
